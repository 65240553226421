<script>
    import { install, installed, isApp } from '$lib/stores/install';
    import { click } from '$lib/util/audio';
    import routes from '$lib/config/header';
    import { page } from '$app/stores';
    import { fade } from 'svelte/transition';

    import SpriteIcon from '$lib/components/icons/SpriteIcon.svelte';
</script>

<header>
    <div class='inner'>
        <div class='mid'>
            <div class='links left notch'>
                {#each routes.filter(r => r.side === 0) as {route, label} (route)}
                    <a href={route} on:click={click} class:selected={$page.url.pathname === route}>
                        <span>{label}</span>
                    </a>
                {/each}
            </div>
            <div class='logowrap'>
                <img class='logo' src='/static/icons/app/logo_blue_512.png' alt='logo'>
            </div>
            <div class='links right notch'>
                {#each routes.filter(r => r.side === 1) as {route, label} (route)}
                    <a href={route} on:click={click} class:selected={$page.url.pathname === route}>
                        <span>{label}</span>
                    </a>
                {/each}
            </div>
        </div>

        {#if !$installed && !$isApp}
            <button class='install' on:click={() => install()} in:fade title='Search items and track your grail progression even while offline!'>
                <SpriteIcon name='download' size='35px' />
                <span>Install</span>
            </button>
        {/if}
    </div>
</header>

<style>
    header {
        display: flex;
        position: sticky;
        top: 0;
        z-index: 10;
        padding: var(--gap-5);
        max-width: var(--main-max-width);
        margin: auto;
    }
    .inner {
        display: flex;
        flex: 1;
        position: relative;
    }
    .install {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        padding: var(--gap-2) var(--gap-4);
        padding-left: var(--gap-3);
        border: 1px solid var(--bg-color-base-light);
        gap: var(--gap-3);
    }
    .install:hover {
        border-color: var(--bg-color-base-lightest);
    }
    .mid {
        display: flex;
        margin: auto;
        flex-direction: row;
        position: relative;
    }
    .links {
        display: flex;
        margin-top: auto;
    }
    .links.left {
        margin-right: -10px;
    }
    .links.right {
        margin-left: -10px;
    }
    a {
        z-index: 1;
        display: flex;
        min-width: 100px;
        min-height: 40px;
        border-width: var(--border-width);

        background-color: var(--btn-color-base);
        border-color: var(--btn-color-base-light);
        border-bottom-color: var(--btn-color-base-dark);
    }
    a.selected {
        background-color: var(--btn-color-yellow);
        border-color: var(--btn-color-yellow-light);
        border-bottom-color: var(--btn-color-yellow-dark);
    }
    .right > a:first-child:not(:last-child) {
        border-right: none;
    }
    .left > a:last-child:not(:first-child) {
        border-left: none;
    }

    a > span,
    .install > span {
        font-family: var(--font-alt);
        font-size: 1.6em;
        transform: translateY(calc(1.6em / 12));
        margin: auto;
    }
    .logowrap {
        z-index: 2;
        position: relative;
        width: 200px;
        height: 155px;
    }
    .logo {
        position: absolute;
        bottom: -23px;
        width: 200px;
        height: 200px;
        filter: drop-shadow(0 0 3px black);
    }
</style>