import { loader } from '$lib/classes/item';
import sleep from '$lib/util/sleep';

/** @type {import('./$types').LayoutLoad} */
export const load = async ({ data, fetch }) => {
    loader(fetch);

    return {
        ...data,
    };
};