import { writable } from 'svelte/store';
import { browser } from '$app/environment';
import notif from '$lib/stores/notif';

export const installed = writable(true);
installed.subscribe(v => installed.value = v);

export const isApp = writable(false);
isApp.subscribe(v => isApp.value = v);

let prompt = null;

const onInstall = () => {
    installed.set(true);
    notif.success('Thanks for installing!');
};

export const install = async () => {
    if (!prompt) return;

    prompt.prompt();
    const choice = await prompt.userChoice;
    
    if (choice !== 'accepted') return;
    onInstall();
};

if (browser) {
    const context = window.matchMedia('(display-mode: standalone)');
    isApp.set(context.matches);

    window.addEventListener('beforeinstallprompt', (event) => {
        console.log('install', event);
        event.preventDefault();
        installed.set(isApp.value);
        prompt = event;
    });

    context.addEventListener('change', (event) => {
        console.log('change', event);
        isApp.set(event.matches);
        if (isApp.value) onInstall();
    });
}
