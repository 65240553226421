<script>
    import { getPrimaryRoute } from '$lib/util/url';
    import { paths } from '$lib/config/public';

    import Header from '$lib/components/Header.svelte';
    import PageTransition from '$lib/components/PageTransition.svelte';
    import Footer from '$lib/components/Footer.svelte';
    import Background from '$lib/components/Background.svelte';
    
    export let data;
</script>

<svelte:head>
    <link rel='prefetch' href={paths.objects} as='fetch' type='application/json'>
    <link rel='prefetch' href={paths.sets}    as='fetch' type='application/json'>
</svelte:head>

<Background />
<Header />
<PageTransition url={getPrimaryRoute(data.pathname)}>
    <slot />
</PageTransition>
<Footer />