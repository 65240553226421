<script>

</script>

<footer>
    <div class='inner'>

    </div>
</footer>

<style>
    footer {
        display: flex;
        flex-direction: column;
        z-index: 10;
        padding: var(--gap-5);
        max-width: var(--main-max-width);
        margin: auto;
    }
</style>