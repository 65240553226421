<script>
    import { fly, fade } from 'svelte/transition';

    export let url;

    // Can't use exit transition since onDestroy won't fire within child components
</script>

<main class='pagetransition'>
    {#key url}
        <div class='fly' in:fly={{x: 200, duration: 100, delay: 0}}>
            <slot />
        </div>
    {/key}
</main>

<style>
    main {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        overflow-x: clip;
    }
    main > * {
        z-index: 1;
        grid-row: 1;
        grid-column: 1;
    }
</style>