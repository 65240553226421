<script>
    import { onMount } from 'svelte';

    let loaded = false;
    onMount(() => loaded = true);
</script>

<div class='bg'>
    <div class='one' class:loaded></div>
    <div class='two' class:loaded></div>
</div>

<style>
    .bg {
        pointer-events: none;
        position: fixed;
        top: 60%;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: visible;
        z-index: 0;
    }
    .one, .two {
        position: absolute;
        top: -400%;
        left: -400%;
        right: -400%;
        bottom: -400%;
        opacity: 0;
        background: conic-gradient(from 0deg at 50% 50%,
            red,
            transparent, 
            yellow,
            transparent, 
            green, 
            transparent,
            cyan, 
            transparent,
            blue, 
            transparent,
            magenta,
            transparent,
            red,
            transparent, 
            yellow,
            transparent, 
            green, 
            transparent,
            cyan, 
            transparent,
            blue, 
            transparent,
            magenta,
            transparent,
            red
        );
        animation: spin 60s linear infinite, hue-rotate 20s linear infinite;
        transition: opacity 1s ease-in;
    }
    .two {
        background: conic-gradient(from 50deg at 50% 50%,
            red,
            transparent, 
            yellow,
            transparent, 
            green, 
            transparent,
            cyan, 
            transparent,
            blue, 
            transparent,
            magenta,
            transparent,
            red,
            transparent, 
            yellow,
            transparent, 
            green, 
            transparent,
            cyan, 
            transparent,
            blue, 
            transparent,
            magenta,
            transparent,
            red,
        );
        animation: spin 40s linear infinite, hue-rotate 10s linear infinite;
    }
    .one.loaded {
        opacity: 3%;
    }
    .two.loaded {
        opacity: 7%;
    }
</style>